import React, { Component } from "react";
import queryString from "query-string";

// Global Components
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import Snackbar from "../../components/Snackbar";
import DeliveryReportTable from "../../components/DeliveryPaginationTableReports";

// Global Style
import { materialStyle } from "../../styles";

// Material
import "date-fns";
import { TextField, Button, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import queryStringModule from "query-string";
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ReportActions } from "../../store/ducks/reports";
import { Actions as LockerActions } from "../../store/ducks/locker";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";
import { Actions as TeamsActions } from "../../store/ducks/teams";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { ExportExcelExtractReports } from "../../components/ExportExcel";

import CardItem from "./components/CardItem";

// configs
import { constants } from "../../config";
import checkHandoverEmail from "../../services/checkHandoverEmail";
import FilterTitle from "../../components/FilterTitle";
const periods = [
  { id: 1, text: "Janeiro" },
  { id: 2, text: "Fevereiro" },
  { id: 3, text: "Março" },
  { id: 4, text: "Abril" },
  { id: 5, text: "Maio" },
  { id: 6, text: "Junho" },
  { id: 7, text: "Julho" },
  { id: 8, text: "Agosto" },
  { id: 9, text: "Setembro" },
  { id: 10, text: "Outubro" },
  { id: 11, text: "Novembro" },
  { id: 12, text: "Dezembro" }
];

const periodsEn = [
  { id: 1, text: "January" },
  { id: 2, text: "Febuary" },
  { id: 3, text: "March" },
  { id: 4, text: "April" },
  { id: 5, text: "Maio" },
  { id: 6, text: "June" },
  { id: 7, text: "July" },
  { id: 8, text: "August" },
  { id: 9, text: "September" },
  { id: 10, text: "October" },
  { id: 11, text: "November" },
  { id: 12, text: "December" }
];


const INITAL_DATE = new Date().setDate(1); // new Date().getUTCDate()
const FINAL_DATE = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  0
);

const DEFAULT_ITEM = Math.random();

const DEFAULT_PERPAGE = 100;

const DEFAULT_QUERY = {
  withUpdates: true,
  withOpening: true,
  withSupplies: true
};

class Reports extends Component {
  state = {
    firstDate: INITAL_DATE,
    secondDate: FINAL_DATE,
    type: 1,
    period: new Date().getUTCMonth() + 1,
    idLocker: "",
    email: "",
    idSubTeam: DEFAULT_ITEM,
    csvData: [],
    idTeam: DEFAULT_ITEM,
    parameters: null,
    full: "no",

    currentEmail: JSON.parse(localStorage.getItem("auth")).user.email
  };

  t = this.props.t;

  handleDefaultQuery = () => {
    const { firstDate, secondDate } = this.state;

    const query = queryString.stringify({
      firstDate: this.handleDate(firstDate),
      secondDate: this.handleDate(secondDate),
      ...DEFAULT_QUERY,
      perPage: DEFAULT_PERPAGE,
      date: this.handleDate(firstDate),
      allCurrentMonth: true,
      full: "no"
    });

    return query;
  };

  componentWillMount() {
    const { reportsRequest, lockersRequest, teamsRequest } = this.props;

    reportsRequest(this.handleDefaultQuery());

    teamsRequest("perPage=1000");
    lockersRequest("perPage=1000");

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    this.setState({ parameters });
  }

  init = () => {
    const {
      reportsRequest,
      lockersRequest,
      handleChangeReports,
      teamsRequest
    } = this.props;
    const { firstDate, secondDate } = this.state;

    reportsRequest(
      `firstDate=${this.handleDate(firstDate)}&secondDate=${this.handleDate(
        secondDate
      )}&perPage=20`
    );

    handleChangeReports(
      "queryString",
      `firstDate=${this.handleDate(firstDate)}&secondDate=${this.handleDate(
        secondDate
      )}&perPage=20`
    );

    teamsRequest("perPage=1000");
    lockersRequest("perPage=1000");
  };

  handleClear = () => {
    const { resetSubTeams } = this.props;
    this.setState({
      firstDate: INITAL_DATE,
      secondDate: FINAL_DATE,
      type: 1,
      period: new Date().getUTCMonth() + 1,
      idLocker: "",
      email: "",
      idSubTeam: "",
      csvData: [],
      idTeam: DEFAULT_ITEM
    });
    this.init();
    resetSubTeams();
  };

  handleDate = date =>
    `${new Date(date).getUTCFullYear()}-${new Date(date).getUTCMonth() +
      1}-${new Date(date).getUTCDate()}`;

  handleChangeFirstDate = date =>
    this.setState({
      firstDate: date
    });

  handleChangeSecondDate = date => this.setState({ secondDate: date });

  // search
  handleSearch = () => {
    const { reportsRequest, handleChangeReports, reports } = this.props;
    const {
      firstDate,
      secondDate,
      idLocker,
      email,
      idSubTeam,
      idTeam,
      full
    } = this.state;

    const parsed = queryStringModule.parse(reports.queryString);

    parsed.firstDate = this.handleDate(firstDate);
    parsed.secondDate = this.handleDate(secondDate);

    parsed.idLocker = idLocker;
    parsed.email = email;
    parsed.idTeam = idTeam;
    parsed.idSubTeam = idSubTeam;

    if (idSubTeam === DEFAULT_ITEM) {
      parsed.idSubTeam = "";
    }

    if (parsed.idTeam === DEFAULT_ITEM) {
      parsed.idTeam = "";
    }

    Object.assign(parsed, {
      ...DEFAULT_QUERY,
      perPage: full === "yes" ? 50000 : DEFAULT_PERPAGE,
      page: 1,
      full
    });

    const stringfied = queryStringModule.stringify(parsed);

    reportsRequest(stringfied);
    handleChangeReports("queryString", stringfied);
  };

  // change global
  handleChange = key => ({ target: { value } }) => {
    const { getSubTeamsRequest, resetSubTeams } = this.props;

    let result = value;

    if (key === "type" && (value === 4 || value === 3)) {
      this.setState({
        firstDate: INITAL_DATE,
        secondDate: FINAL_DATE
      });
    } else if (key === "idTeam") {
      if (value === DEFAULT_ITEM) {
        this.setState({
          idSubTeam: ""
        });
        resetSubTeams();
      } else {
        getSubTeamsRequest(
          queryStringModule.stringify({ perPage: 1000, idTeam: value })
        );
      }
    }

    this.setState({ [key]: result });
  };

  // change for dates for periods
  handleChangePeriod = ({ target: { value } }) => {
    this.setState({
      firstDate: `${new Date().getUTCFullYear()}-${value}-1`,
      secondDate: `${new Date().getUTCFullYear()}-${value}-31`,
      period: value
    });
  };

  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  render() {
    const { reports, classes, lockers, subTeams, teams } = this.props;
    const { type } = this.state;

    return (
      <Container title="relatórios">
        <ThemeTextFildGreen>
          {reports.error && (
            <Snackbar
              message="um erro aconteceu"
              variant="error"
              onClose={() => {}}
            />
          )}
          {reports.loading ? (
            <Spinner />
          ) : (
            <>
              <FilterTitle />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <TextField
                      disabled={type === 2 ? true : false}
                      id="custom-css-outlined-input"
                      select
                      label={this.t("filters.period")}
                      className={classes.textFieldLocker1}
                      value={this.state.period}
                      onChange={this.handleChangePeriod}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      {(this.props.i18n.language === 'en' ? periodsEn : periods).map(item => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            disabled={
                              periods.findIndex(p => p.id === item.id) >
                              new Date().getMonth()
                            }
                          >
                            {item.text}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      disabled={!lockers.data.length}
                      id="custom-css-outlined-input"
                      select
                      label={this.t("filters.select_locker")}
                      className={classes.textFieldLocker}
                      value={this.state.idLocker}
                      onChange={this.handleChange("idLocker")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                      helperText={
                        lockers.loading
                          ? ""
                          : lockers.data.length
                            ? ""
                            : "Nenhum cadastrado"
                      }
                    >
                      {lockers.data.length &&
                        lockers.data.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.lockerNumber} - {item.address}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  {teams.data.length >= 2 && (
                    <Grid item xs={12} md={2} className="form-colaboradore-sm">
                      <TextField
                        disabled={!teams.data.length || teams.loading}
                        id="custom-css-outlined-input"
                        select
                        label={"Cliente"}
                        className={classes.textFieldLocker}
                        value={this.state.idTeam}
                        onChange={this.handleChange("idTeam")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                        margin="normal"
                        variant="outlined"
                        helperText={
                          teams.loading
                            ? ""
                            : teams.data.length
                              ? ""
                              : "Nenhum cadastrado"
                        }
                      >
                        <MenuItem
                          selected
                          key={DEFAULT_ITEM}
                          value={DEFAULT_ITEM}
                        >
                          Todos
                        </MenuItem>
                        {teams.data.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}

                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <TextField
                      disabled={!subTeams.data.length || subTeams.loading}
                      id="custom-css-outlined-input"
                      select
                      label={this.t("filters.place")}
                      className={classes.textFieldLocker}
                      value={this.state.idSubTeam}
                      onChange={this.handleChange("idSubTeam")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      <MenuItem key={DEFAULT_ITEM} value={DEFAULT_ITEM}>
                        {this.t('common:all')}
                      </MenuItem>
                      {subTeams.data.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.identifierCode} - {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {checkHandoverEmail(this.state.currentEmail) && (
                    <Grid item xs={12} md={2} className="form-colaboradore-sm">
                      <TextField
                        disabled={reports.loading}
                        id="custom-css-outlined-input"
                        select
                        label={"Relatório completo"}
                        className={classes.textFieldLocker}
                        value={this.state.full}
                        onChange={this.handleChange("full")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem key={"no"} value={"no"}>
                          Não
                        </MenuItem>
                        <MenuItem key={"yes"} value={"yes"}>
                          Sim
                        </MenuItem>
                      </TextField>
                    </Grid>
                  )}
                </Grid>
              </MuiPickersUtilsProvider>
              <br />
              <Grid container spacing={2}>
                <Button
                  onClick={this.handleSearch}
                  variant="contained"
                  className={classes.button}
                  style={{
                    backgroundColor: "#006660",
                    color: "#fff",
                    textTransform: "lowercase",
                    fontSize: 14,
                    height: 35
                  }}
                >
                  {this.t("filters.search")}
                </Button>
                <Button
                  variant="contained"
                  onClick={this.handleClear}
                  className={classes.button}
                  style={{
                    backgroundColor: "#006660",
                    color: "#fff",
                    textTransform: "lowercase",
                    fontSize: 14,
                    height: 35
                  }}
                >
                  {this.t("filters.period")}
                </Button>
                <ExportExcelExtractReports
                  data={reports.deliveries.data}
                  othersSupplies={reports.deliveries.suppliesMonth}
                  othersCompartmentOpening={
                    reports.deliveries.compartmentOpeningMonth
                  }
                  classes={classes}
                  textSecretCode={
                    this.state.parameters.textSecretCode || "Código"
                  }
                />
              </Grid>
              <Box
                className={classes.box}
                display="flex"
                p={1}
                m={1}
                bgcolor="background.paper"
                id="content-block-report-sm"
                justifyContent="space-between"
              >
                <CardItem
                  label={this.t("metrics.total")}
                  numberInfo={
                    <p id="text_Card_Item">{reports.deliveries.total || 0}</p>
                  }
                />
                {/* <CardItem
                  label={this.t("metrics.waiting_to_deposit")}
                  numberInfo={
                    <p id="text_Card_Item">
                      {reports.data ? reports.data.inDelivery : 0}
                    </p>
                  }
                /> */}
                <CardItem
                  label={this.t("metrics.waiting_to_collect")}
                  numberInfo={
                    <p id="text_Card_Item">
                      {reports.data ? reports.data.inCollect : 0}
                    </p>
                  }
                />
                {/* <CardItem
                  label={this.t("metrics.waiting_to_return")}
                  numberInfo={
                    <p id="text_Card_Item">
                      {reports.data ? reports.data.inReloaded : 0}
                    </p>
                  }
                /> */}
                <CardItem
                  label={this.t("metrics.returned_order")}
                  numberInfo={
                    <p id="text_Card_Item">
                      {reports.data ? reports.data.inReloadedReturned : 0}
                    </p>
                  }
                />
                <CardItem
                  label={this.t("metrics.finished")}
                  numberInfo={
                    <p id="text_Card_Item">
                      {reports.data ? reports.data.isFinished : 0}
                    </p>
                  }
                />
              </Box>
              <DeliveryReportTable />
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  lockers: state.locker,
  subTeams: state.subTeams,
  teams: state.teams
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReportActions,
      ...LockerActions,
      ...SubTeamActions,
      ...TeamsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("reports")(withStyles(materialStyle)(Reports)));
